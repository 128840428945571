<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">组照投稿</h1>
          <a class="btn-back right" href="">返回</a>
        </div>
        <div class="contest-con">
          <div class="tips" style="color:#EF7F48;font-size: 16px;">
            *你正在进行组照投稿，请上传 {{gourp_limit.start}}-{{gourp_limit.end}} 幅专题作品并添加相应描述。
          </div>
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            class=""
            label-width="120px"
          >
            <div class="info-box info-box2">
              <el-form-item label="作品名称：" prop="mname">
                <el-input
                  v-model="form.mname"
                  placeholder="请输入15字以内的作品名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-for="(item, index) in define"
                :label="item + '：'"
                :prop="custom_fields[index]"
                :key="index"
              >
                <el-input
                  v-model="form.custom_fields[index]"
                  :placeholder="'请输入'+item"
                ></el-input>
              </el-form-item>
              <el-form-item label="作品拍摄地：" prop="shoot_address" v-if="gourp_limit.is_open_psd>0">
                <el-input
                        v-model="form.shoot_address"
                        placeholder="请输入作品拍摄地"
                ></el-input>
              </el-form-item>
              <el-form-item label="作品描述：" prop="remark">
                <el-input
                  type="textarea"
                  class="info-text"
                  placeholder="请输入500字以内作品描述..."
                  v-model="form.remark"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </div>
            <p class="tips-txt" v-show="msg">
              {{msg}}
            </p>
            <input type="file" class="ipt-img" @change="addImg($event)" name="file[]"  multiple style="display: none" id="addBtns">
            <input type="file" class="chg-img" @change="oneAddImg($event)" style="display: none" id="addBtn">
            <div class="add-list">
              <div class="add-item" v-for="(i,index) in list" :key="index">
                <div class="add-itemhd">
                  <a class="btn-add" href="javascript:void(0)" @click="toAddImgs(index)">
                    <img :src="uploadBg" />添加图片
                  </a>
                  <span class="">添加图片</span>
                </div>
                <div class="add-itembd">
                  <div class="pic-wrap">
                    <img :src="i.url" class="pic" @click="toAddImg(index)" title="更换图片">
                      <div class="imgLoading-bg" v-show="i.percent == 1">
                        <div class="imgLoading"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>
                      </div>
                    <span class="item-id" v-show="i.percent >0">{{ index+1 }}</span>
                  </div>
                  <div class="info-box">
                    <div class="info-item required">
                      <span class="label"><span style="color:#EF7F48;">*</span>作品标题：</span>
                      <input type="text" :placeholder="imgTitle" class="ipt" v-model="i.title">
                    </div>
                    <div class="info-item">
                      <span class="label">作品描述：</span>
                      <textarea  class="info-text"  placeholder="请添加拍摄时间、地点和文字描述" v-model="i.describe"></textarea>
                    </div>
                    <div class="btn-wrap">
                      <a class="btn-up" href="javascript:void(0)"  @click="moveUp(index)" v-if="index>0"></a>
                      <a class="btn-down" href="javascript:void(0)"  @click="moveDown(index)" v-if="index<list.length-1"></a>
                      <a class="btn-del" href="javascript:void(0)"  @click="delFindingItem(index)" v-show="list.length>1"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-box">
              <div class="submit-item">
                <label>
                  <input
                    v-model="is_finding"
                    class="check"
                    type="checkbox"
                    :checked="isAgreement"
                    @click="isAgreement = !isAgreement"
                  />
                  <span class="text">我同意将该图片同步到app发现中</span>
                </label>
              </div>
              <div class="agreement-tips">
                发布即表示您已同意我们的<a href="http://www.pai2345.com/web/Findworks/agreement" target="_blank">原创条款</a>
              </div>
              <div class="submit-item">
                <a class="btn-w" href="javascript:void (0);" @click="toAddImgs('a')"
                  ><img
                    src="http://www.pai2345.com/static/web/finding/images/finding-addimg.png"
                  />继续添加图片</a
                >
                <a class="btn" href="javascript:;" @click="submitForm()">提交</a>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadBg: require("@/static/images/1223/btn-add.png"),
      dialogImageUrl: "",
      dialogVisible: false,
      id: this.$route.params.id,
	  group: this.$route.query.group,
      define: [],
        // 是否同意条款
       isAgreement: false,
      form: {
        mname: "",
        remark: "",
        custom_fields: [],
        imgs: []
      },
      rules: {
        mname: [
          { required: true, message: "作品名称不能为空", trigger: "blur" }
        ],
        remark: [{ message: "作品描述不能为空", trigger: "blur" }]
      },
      // 自定义字段
      custom_fields: ["自定义1", "自定义2"],
      // 上传列表
      list: [
        {
          id:'',
          title: "",
          describe: "",
          url: require("@/static/res/images/0905/picbg.png"),
          percent:0,
        }
      ],
        domIdx:0,
        //图片的限定
        gourp_limit:{
            typestr:'',
            size:20,
            end:2,
            start:1,
            imgtype:[],
            is_open_psd:0,
        },
      // 是否同意发布到app
      is_finding: true,
        msg:'',

    };
  },
    computed: {
        imgTitle:function () {
            if(this.mname){
                return this.mname;
            }else {
                return '请输入图片标题';
            }
        }
    },
  methods: {
    triggerUpload() {
      document
        .getElementById("uploadFile")
        .querySelector(".el-upload__input")
        .click();
    },
    hideView() {
      this.isUploaded = false;
    },
    handleSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // beforeUpload(file) {
    //   const isJPG = file.type === "image/jpeg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isJPG) {
    //     this.$message.error("上传头像图片只能是 JPG 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
      //上传图片
      toAddImgs: function(idx){
          this.domIdx =idx;
          document.getElementById("addBtns").click();
      },
      toAddImg: function(idx){
          this.domIdx =idx;
          document.getElementById("addBtn").click();
      },
      setInit:function(){
          sessionStorage.setItem('initType',1);
      },
      oneAddImg:function(e)
      {
          let idx = this.domIdx;
          let filesArr = e.target.files;
          if(filesArr[0]){
              if(filesArr[0].size <= this.gourp_limit.size*1024*1024){
                  if(!this.tools.hasImgType(this.gourp_limit.imgtype,filesArr[0].type)){
                      this.$message.error("上传头像图片只能是 "+this.gourp_limit.typestr+" 格式!");
                  }else {
                      this.list[idx].percent = 1;
                      this.list[idx].url = '';
                      this.tools.uploadaly(filesArr[0]).then(res=>{
                          this.list[idx].percent= 2;
                          if(res){
                              this.list[idx].url = res;
                              this.$forceUpdate();
                          }
                      })
                      this.$forceUpdate();
                  }
              }else {
                  this.$message('上传图片不能超过'+this.gourp_limit.size+'M');
              }
          }
          e.target.value = '';
      },
      addImg:function(e)
      {
          let idx = this.domIdx;
          let filesArr = e.target.files;
          var len = filesArr.length;
          for(var j=0;j<len;j++){
              if(filesArr[j].size > this.gourp_limit.size*1024*1024){
                  this.$message('上传图片不能超过'+this.gourp_limit.size+'M');
                  break;
              }
              if(!this.tools.hasImgType(this.gourp_limit.imgtype,filesArr[j].type)){
                  this.$message.error("上传头像图片只能是 "+this.gourp_limit.typestr+" 格式!");
                  break;
              }
          }
          if(j == len){
              let count = len;
              var domIdx = 0;
              for(let j=0;j<this.list.length;j++){
                  if(this.list[j].percent == 2){
                      count++;
                  }
              }
              var fase = true;
              if(this.gourp_limit.end > 0){
                  if(count> this.gourp_limit.end){
                      fase=false;
                      this.$message('您上传的图片超过上限'+this.gourp_limit.end+'张，请调整后重试！');
                  }
              }
              if(fase){
                  if(idx == 'a'){
                      for(var i=0; i<len; i++){
                          let obj ={'id':'','title':'','describe':'', 'url':'/static/web/finding/images/img-bg.png','percent':1};
                          this.list.push(obj);
                          this.tools.uploadaly(filesArr[i]).then(res=>{
                              if(res){
                                  obj.percent= 2;
                                  obj.url = res;
                                  domIdx++;
                                  if(domIdx === len){
                                      this.del_obj();
                                  }
                              }
                          })
                      }
                  }else{
                      for(let i=0; i<len; i++){
                          let obj ={'id':'','title':'','describe':'', 'url':'/static/web/finding/images/img-bg.png','percent':1};
                          var index = i+idx;
                          this.list.splice(index,0,obj);
                          this.tools.uploadaly(filesArr[i]).then(res=>{
                              if(res){
                                  obj.percent= 2;
                                  obj.url = res;
                                  domIdx++;
                                  if(domIdx === len){
                                      this.del_obj();
                                  }
                              }
                          })
                      }
                  }
              }
          }
          e.target.value = '';
      },
      delFindingItem:function (idx) {
          this.list.splice(idx,1);
      },
      moveUp:function (idx) {
          let obj = this.list[idx-1];
          this.list[idx-1] = this.list[idx];
          this.list[idx] = obj;
          this.$forceUpdate();
      },
      moveDown:function (idx) {
          let obj = this.list[idx+1];
          this.list[idx+1] = this.list[idx];
          this.list[idx] = obj;
          this.$forceUpdate();
      },
      del_obj:function(){
          if(this.list.length > 1 ){
              for(let i= this.list.length-1;i>=0;i--){
                  if(this.list[i].percent < 1){
                      this.list.splice(i,1);
                  }
              }
          }
      },
    submitForm() {
	  let that = this;
	    this.$refs["form"].validate(valid => {
	      if (valid) {
              var arr = [];
              var imgArr = this.list;
              var fase = true;
              if(this.form.mname == ''){
                  this.$message.error("作品名称不能为空");fase=false;
              }
              if(fase){
                  for(var i=0;i<imgArr.length;i++){
                      if(imgArr[i].percent == 2){
                          arr.push(imgArr[i]);
                      }
                      if(imgArr[i].percent == 1){
                          this.$message.error("图片上传中，请稍候");
                          fase = false;
                          break;
                      }
                  }
              }
              var start = 2;
              if(this.start > 0){
                  start = this.gourp_limit.start;
              }
              if(fase && arr.length < start){
                  fase = false;
                  this.$message("您上传的图片至少" + start + "张，请调整后重试！");
              }
              if(fase && this.gourp_limit.end > 0){
                  if(arr.length > this.gourp_limit.end){
                      fase = false;
                      this.$message("您上传的图片超过上限" + this.gourp_limit.end + "张，请调整后重试！");
                  }
              }
              if(fase && this.define.length>0){
                  for (var n=0;n<this.define.length;n++){
                      if(this.form.custom_fields[n] == '' || this.form.custom_fields[n] == undefined){
                          this.$message.error(this.define[n]+"必须填写");fase=false;
                          break;
                      }
                  }
              }
              if(fase && this.gourp_limit.is_open_psd == 2){
                  if(this.form.shoot_address == ''){
                      this.$message.error("图片拍摄地必须填写");fase=false;
                  }
              }
              for (var j=0;j<arr.length;j++){
                  if(arr[j].title == ''){
                      var num = j+1;
                      arr[j].title = this.form.mname +'（'+num+'）';
                  }
              }
              if(fase){
                  this.axios
                      .post("/match/join_multi",
                          {
							  work_id: this.$route.query.work_id,
                              match_id: that.id,
                              mname: this.form.mname,
                              remark: this.form.remark,
                              imgs: arr,
                              group: this.group,
                              shoot_address:this.form.shoot_address,
                              custom_fields: this.form.custom_fields,
                              is_finding:this.isAgreement,
                              works_type:3,
                              source:2
                          }
                      )
                      .then(res => {
                          if(res.data.code == 200){
                              this.$confirm('投稿成功!', '确认信息', {
                                  distinguishCancelAndClose: true,
                                  confirmButtonText: '再投一稿',
                                  cancelButtonText: '返回我的作品'
                              })
                                  .then(() => {
                                      var initUrl = window.location.href;
                                      initUrl = initUrl.replace("work_id","init");
                                      window.location.href = initUrl;
                                      window.location.reload();
                                  })
                                  .catch(action => {
                                      console.log(action);
                                      this.$router.push({
                                          path: '/detail/'+ that.id+'/myworks'
                                      });
                                  });
                          }else {
                              this.$message(res.data.msg)
                          }
                          this.has_submit = true;
                      },err=>{
                          this.has_submit = true;
                          console.log(err);
                      });
              }
	      } 
	    });
	
    },
    overLimit() {
      console.log("图片超过数量了");
    },
    sucessUpload(res, file, fileList) {
      console.log(res, file, fileList);
    },

    addPic() {
      // 查看是否第一张图，并且没有上次过，是就传第一张
      // 否则就在原先数组最后加入
    },
      init(){
          this.tools.getMatchDetail({id:this.id,group:this.group}).then(res=>{
              this.define = res.custom_fields;
              this.msg = res.match_limit.match_introduce;
              var params = res.gourp_limit;
              if(res.match_limit.is_app_show == 1){
                  this.isAgreement = true;
              }
              if(params){
                  this.gourp_limit = Object.assign(this.gourp_limit,params);
                  this.gourp_limit.size = params.file_limit;
                  this.gourp_limit.imgtype = params.file_limit_type;
                  this.gourp_limit.typestr = this.gourp_limit.imgtype.toString();
              }
          })
      },
  },
  mounted() {
      if(this.$route.query.work_id){
          this.tools.getWorksDetail({work_id:this.$route.query.work_id}).then(res=>{
              this.form = res.form;
              this.list = res.imgs;
              this.id = this.form.m_id;
              this.group = this.form.group;
              this.init();
          });
      }else {
          this.init();
      }
  }
};
</script>

<style lang="scss">
.info-box2 .info-item .label {
  min-width: 100px;
  text-align: right;
  span {
    margin-right: 3px;
  }
}
.contest-con .info-box textarea.el-textarea__inner {
  font-family: "Microsoft Yahei", sans-serif;
  outline: none;
  resize: none;
  height: 100px;
}
.el-form-item__label {
  font-size: 18px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.el-input__inner,
.el-textarea__inner {
  font-size: 16px;
}
.el-input__inner:focus,
.el-input__inner:hover,
.el-textarea__inner:focus,
.el-textarea__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 0;
}
.uploader,
.el-upload,
.el-upload img {
  width: 100%;
  height: 100%;
}
// .el-upload img{
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
// }
</style>
