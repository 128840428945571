<template>
  <router-view></router-view>
</template>

<script>
// @ is an alias to /src
// import Head from "@/layouts/Head.vue";

export default {
  name: "Contribute"
};
</script>

<style lang="scss"></style>
