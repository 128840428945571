<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">视频投稿</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
        </div>
        <div class="contest-con">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="120px"
            class="demo-ruleForm info-box clearfix"
          >
            <div class="info-box clearfix">
              <div class="left">
                <el-form-item label="作品名称：" prop="name">
                  <el-input
                    v-model="form.mname"
                    placeholder="请输入作品名称"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-for="(item, index) in define"
                  :label="item + '：'"
                  :prop="custom_fields[index]"
                  :key="index"
                >
                  <el-input
                    v-model="form.custom_fields[index]"
                    :placeholder="'请输入'+item"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作品拍摄地：" prop="shoot_address" v-if="gourp_limit.is_open_psd>0">
                  <el-input
                          v-model="form.shoot_address"
                          placeholder="请输入作品拍摄地"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作品描述：" prop="desc">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.remark"
                    maxlength="1000"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                <!-- <div class="info-item">
                  <span class="label">作品名称：</span>
                  <input
                    class="ipt"
                    type="text"
                    placeholder="请输入15字以内作品名称"
                  />
                </div>
                <div class="info-item">
                  <span class="label">作品描述：</span>
                  <textarea
                    class="info-text"
                    placeholder="请输入200字以内作品描述..."
                    maxlength="200"
                  ></textarea>
                </div> -->
                <div class="info-item info-item2">
                  <!-- <a class="btn btn-uplvideo" href="javascript:void(0)">
                    <img :src="uploadVideoBg" />
                  </a> -->
                    <p class="tips-txt" v-show="msg"  style="margin-top: -10px;">
                        {{msg}}
                    </p>
                  <el-upload class="btn btn-uplvideo"
                             :action="videoUrl"
                             v-bind:on-progress="uploadVideoProcess"
                             v-bind:before-upload="beforeUploadVideo"
                             :data="ossParams"
                             :on-success="handleVideoSuccess"
                             v-bind:show-file-list="false">
                    <img :src="uploadVideoBg"/>
                  </el-upload>
                  <!--<a-->
                    <!--class="btn btn-uplvideo"-->
                    <!--href="javascript:void(0)"-->
                    <!--@click="triggerUpload()"-->
                  <!--&gt;-->
                    <!--&lt;!&ndash; <input class="file" type="file" multiple /> &ndash;&gt;-->
                    <!--<img :src="uploadVideoBg"/></a>-->
                  <!--<input-->
                    <!--class="file"-->
                    <!--type="file"-->
                    <!--id="single"-->
                    <!--accept="video/mp4"-->
                    <!--style="visibility: hidden"-->
                  <!--/>-->
                </div>
                <div class="upl-box" v-show="videoFlag">
                  <div class="filename">{{videoName}}</div>
                  <div class="upl-progress">
                    <div class="progress-bar">
                      <div class="progress-cur" :style="'width: '+videoUploadPercent+'%'"></div>
                    </div>
                    <div class="progress-txt">{{videoUploadPercent}}%</div>
                  </div>
                  <!--<a class="btn-cancel" href="">取消上传</a>-->
                </div>
              </div>
              <div class="right">
                <el-upload
                  class="upl-cover"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :http-request="handleUpload"
                >
                  <img
                    v-if="imageUrl"
                    :src="imageUrl"
                    class="cover"
                    @click="clearTips()"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div class="imgLoading-bg" v-show="loading">
                      <div class="imgLoading"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>
                  </div>
                </el-upload>
                <p class="error"><span v-show="isShowTips">请上传视频封面</span></p>
                <!-- <div class="upl-cover">
                  <img class="cover" :src="cover" />
                  <input type="file" id="uplcover" accept="image/jpeg" />
                </div> -->
              </div>
            </div>
            <div class="submit-box">
              <!--<div class="submit-item">-->
                <!--<label>-->
                  <!--<input-->
                    <!--class="check"-->
                    <!--type="checkbox"-->
                    <!--v-model="is_finding"-->
                    <!--checked-->
                  <!--/>-->
                  <!--<span class="text">我同意将该图片同步到app发现中</span>-->
                <!--</label>-->
              <!--</div>-->
              <div class="agreement-tips">
                发布即表示您已同意我们的<a href="http://www.pai2345.com/web/Findworks/agreement" target="_blank">原创条款</a>
              </div>
              <div class="submit-item">
                <a class="btn" href="javascript:;" @click="submitForm()">提交</a>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
	  group: this.$route.query.group,
      // 是否同意条款
      is_finding: true,
      define: [],
      custom_fields: [],
      form: {
        mname: "",
        remark: "",
        custom_fields: [],
        video:"",
          shoot_address:'',
      },
      rules: {
        mname: [
          { required: true, message: "请输入作品名称", trigger: "blur" },
          { min: 0, max: 15, message: "长度在 15 个字符", trigger: "blur" }
        ],
        remark: [{ required: true, message: "请输入作品描述", trigger: "blur" }]
      },
      formData: {},
      uploadVideoBg: require("@/static/images/1223/btn-1.png"),
      imageUrl: require("@/static/images/1223/video-cover.png"),
      imageBg: require("@/static/images/1223/video-cover.png"),
      isShowTips: false,
        loading:false,
        videoFlag:false,
        videoUploadPercent:20,
        ossParams:{},
        videoUrl:'/aly',
        videoName:'文件名',
        msg:'',
        //图片的限定
        gourp_limit:{
            typestr:'',
            size:20,
            end:2,
            start:1,
            imgtype:[],
            is_open_psd:0,
            video_limit:300
        },
    };
  },
  methods: {
      beforeUploadVideo(file) {
          var fileSize = file.size / 1024 / 1024 < this.gourp_limit.video_limit;
          //, 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'
          if (['video/mp4'].indexOf(file.type) == -1) {
              this.$message.error("请上传正确的视频格式");
              return false;
          }
          if (!fileSize) {
              this.$message.error("视频大小不能超过"+this.gourp_limit.video_limit+"MB");
              return false;
          }
          var _this = this;
          _this.videoName = file.name;
          var promise = new Promise((resolve, reject) => {
              _this.tools.getMd5(file).then(md5=>{
                  _this.axios.post("/match/params", { name: file.name, md5: md5 ,dir:'matchVideo' },)
                      .then(function (response) {
                          var params = response.data;
                          if(params.code == 200){
                              var res = params.data;
                              _this.ossParams = res;
                              _this.ossParams.name = file.name;
                              _this.ossParams.OSSAccessKeyId = res.id;
                              _this.ossParams.success_action_status = '200';
                              _this.ossParams.callback = '';
                              _this.ossParams.key = res.key;
                              _this.ossParams.host = res.url;
                              //打包时候需要打开
                             _this.videoUrl = res.url;
                          }
                          file.key= _this.ossParams.key;
                          file.host= _this.ossParams.host;
                          file.progress= 0;
                          file.imgUrl='';
                          resolve()
                      })
                      .catch(function (error) {
                          console.log(error, '错误');
                          reject(error)
                      })
              })
          })
          return promise
      },
      //进度条
      uploadVideoProcess(event, file) {
          this.videoFlag = true;
          this.videoUploadPercent = file.percentage.toFixed(0) * 1;
      },
      //上传成功回调
      handleVideoSuccess(res,file) {
          var files = file.raw;
          this.videoUploadPercent = 100;
          this.form.video = files.host +'/'+ files.key;
      },
      // handleUploadVideo(option) {
      //     var file = option.file;
      //     var that = this;
      //     that.tools.uploadaly(file, function(res) {
      //         console.log(res);
      //     });
      // },
    triggerUpload() {
      document
        .getElementById("single")
        .click();
    },
    clearTips() {
      this.isShowTips = false;
    },
    handleAvatarSuccess(res, file) {
      // 上传成功改变图片值
      this.imageUrl = URL.createObjectURL(file.raw);
      // 图片上传成功就隐藏提示框
      this.isShowTips = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
      handleUpload(option) {
          var file = option.file;
          var that = this;
          that.loading = true;
          this.tools.uploadaly(file).then(res=>{
              if (res) {
                  that.imageUrl = res;
                  that.loading = false;
              } else {
                  console.log(res);
              }
          })
      },
    submitForm() {
      let that = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.imageUrl == this.imageBg) {
            this.isShowTips = true;
          } else {
              var fase = true;
              if(this.form.mname == ''){
                  this.$message.error("作品名称不能为空");fase=false;
              }
              if (fase && this.imageUrl == this.imageBg) {
                  this.isShowTips = true;fase=false;
              }
              if(fase && this.loading){
                  this.$message.error("图片上传中，请稍候...");fase=false;
              }
              if(fase && this.gourp_limit.is_open_psd == 2){
                  if(this.form.shoot_address == ''){
                      this.$message.error("图片拍摄地必须填写");fase=false;
                  }
              }
              if(fase && this.define.length>0){
                  for (var n=0;n<this.define.length;n++){
                      if(this.form.custom_fields[n] == '' || this.form.custom_fields[n] == undefined){
                          this.$message.error(this.define[n]+"必须填写");fase=false;
                          break;
                      }
                  }
              }
              if(fase && this.form.video == ''){
                  this.$message.error("视频必须上传");fase=false;
              }
              if(fase){
                  this.axios
                      .post("/match/join_single",
                          {
                              work_id: this.$route.query.work_id,
                              match_id: that.id,
                              mname: this.form.mname,
                              remark: this.form.remark,
                              video: this.form.video,
                              image: this.imageUrl,
                              group: this.group,
                              shoot_address:this.form.shoot_address,
                              custom_fields: this.form.custom_fields,
                              works_type:4,
                              source:2
                          }
                      )
                      .then(res => {
                          if(res.data.code == 200){
                              this.$confirm('投稿成功!', '确认信息', {
                                  distinguishCancelAndClose: true,
                                  confirmButtonText: '再投一稿',
                                  cancelButtonText: '返回我的作品'
                              })
                                  .then(() => {
                                      var initUrl = window.location.href;
                                      initUrl = initUrl.replace("work_id","init");
                                      window.location.href = initUrl;
                                      window.location.reload();
                                  })
                                  .catch(action => {
                                      console.log(action);
                                      this.$router.push({
                                          path: '/detail/'+ that.id+'/myworks'
                                      });
                                  });
                          }else {
                              this.$message(res.data.msg)
                          }
                          this.has_submit = true;
                      },err=>{
                          this.has_submit = true;
                          console.log(err);
                      });
              }
          }
        } else {
          if (this.imageUrl == this.imageBg) {
            this.isShowTips = true;
          }
        }
      });
    },
      init(){
          this.tools.getMatchDetail({id:this.id,group:this.group}).then(res=>{
              this.define = res.custom_fields;
              this.msg = res.match_limit.match_introduce;
              var params = res.gourp_limit;
              if(params){
                  this.gourp_limit = Object.assign(this.gourp_limit,params);
              }
          })
      }
  },
  mounted() {

      if(this.$route.query.work_id){
          this.tools.getWorksDetail({work_id:this.$route.query.work_id}).then(res=>{
              this.form = res.form;
              this.imageUrl = res.image;
              this.videoName = res.form.video;
              this.videoFlag = true;
              this.videoUploadPercent = 100;
              this.id = this.form.m_id;
              this.group = this.form.group;
              this.init();
          });
      }else {
          this.init();
      }
  }
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.contest-submit .info-box .left textarea {
  width: 100%;
}
.el-form-item__label {
  font-size: 18px;
}
.el-input__inner:focus,
.el-input__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.error {
  line-height: 24px;
  height: 24px;
  color: #f56c6c;
}
.btn.disabled {
  background: #ddd;
  border-color: #ddd;
}
</style>
