<template>
  <div class="amap-wrapper">
    <div class="amap-inner">
      <!-- <div class="close">关闭</div> -->
      <div class="container">
        <div class="search-box">
          <input v-model="searchKey" type="search" id="search" />
          <button @click="searchByHand"><i class="el-icon-search"></i></button>
          <div class="tip-box" id="searchTip"></div>
        </div>
        <!--
        amap-manager： 地图管理对象
        vid：地图容器节点的ID
        zooms： 地图显示的缩放级别范围，在PC上，默认范围[3,18]，取值范围[3-18]；在移动设备上，默认范围[3-19]，取值范围[3-19]
        center： 地图中心点坐标值
        plugin：地图使用的插件
        events： 事件
      -->
        <el-amap
          class="amap-box"
          :amap-manager="amapManager"
          :vid="'amap-vue'"
          :zoom="zoom"
          :plugin="plugin"
          :center="center"
          :events="events"
        >
          <!-- 标记 -->
          <el-amap-marker
            v-for="(marker, index) in markers"
            :position="marker"
            :key="index"
          ></el-amap-marker>
        </el-amap>
      </div>
      <div class="toolbar">
        <p><span>当前选择的地点：</span>{{ address }}</p>
        <p class="toolbar-bd">
          <el-button @click="confirm">确定</el-button>
          <el-button @click="cancel">关闭</el-button>
        </p>
        <!-- position: [{{ lng }}, {{ lat }}] address: {{ address }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager();
export default {
  props: {
    location: {
      type: String,
    },
  },
  data() {
    let self = this;
    return {
      address: null,
      searchKey: "",
      amapManager,
      markers: [],
      searchOption: {
        city: "全国",
        citylimit: true,
      },
      center: [121.329402, 31.228667],
      zoom: 17,
      lng: 0,
      lat: 0,
      loaded: false,
      events: {
        init() {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initSearch();
          });
        },
        // 点击获取地址的数据
        click(e) {
          // console.log(e)
          self.markers = [];
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.center = [lng, lat];
          self.markers.push([lng, lat]);
          // 这里通过高德 SDK 完成。
          let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          geocoder.getAddress([lng, lat], function(status, result) {
            if (status === "complete" && result.info === "OK") {
              if (result && result.regeocode) {
                // console.log(result.regeocode.formattedAddress);
                self.address = result.regeocode.formattedAddress;
                self.searchKey = result.regeocode.formattedAddress;
                self.$nextTick();
              }
            }
          });
        },
      },
      // 一些工具插件
      plugin: [
        // {
        //   pName: 'Geocoder',
        //   events: {
        //     init (o) {
        //       console.log(o.getAddress())
        //     }
        //   }
        // },
        {
          // 定位
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng;
                  // 设置维度
                  self.lat = result.position.lat;
                  // 设置坐标
                  self.center = [self.lng, self.lat];
                  self.markers.push([self.lng, self.lat]);
                  // load
                  self.loaded = true;
                  // 页面渲染好后
                  self.$nextTick();
                }
              });
            },
          },
        },
        {
          // 工具栏
          pName: "ToolBar",
          events: {
            // init(instance) {
            //   // console.log(instance);
            // },
          },
        },
        {
          // 鹰眼
          pName: "OverView",
          events: {
            // init(instance) {
            //   // console.log(instance);
            // },
          },
        },
        {
          // 地图类型
          pName: "MapType",
          defaultType: 0,
          events: {
            // init(instance) {
            //   // console.log(instance);
            // },
          },
        },
        {
          // 搜索
          pName: "PlaceSearch",
          events: {
            // init(instance) {
            //   // console.log(instance)
            // },
          },
        },
      ],
    };
  },
  methods: {
    initSearch() {
      let vm = this;
      let map = this.amapManager.getMap();
      AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
        var poiPicker = new PoiPicker({
          input: "search",
          placeSearchOptions: {
            map: map,
            pageSize: 10,
          },
          suggestContainer: "searchTip",
          searchResultsContainer: "searchTip",
        });
        vm.poiPicker = poiPicker;
        // 监听poi选中信息
        poiPicker.on("poiPicked", function(poiResult) {
          // console.log(poiResult)
          let source = poiResult.source;
          let poi = poiResult.item;
          if (source !== "search") {
            poiPicker.searchByKeyword(poi.name);
          } else {
            poiPicker.clearSearchResults();
            vm.markers = [];
            let lng = poi.location.lng;
            let lat = poi.location.lat;
            let address = poi.cityname + poi.adname + poi.name;
            vm.center = [lng, lat];
            vm.markers.push([lng, lat]);
            vm.lng = lng;
            vm.lat = lat;
            // console.log(address);
            vm.address = address;
            vm.searchKey = address;
          }
        });
      });
    },
    searchByHand() {
      if (this.searchKey !== "") {
        this.poiPicker.searchByKeyword(this.searchKey);
      }
    },
    confirm() {
      // console.log(this.address);
      this.$emit("changeLocation", this.address);
    },
    cancel() {
      this.$emit("changeLocation");
    },
  },
};
</script>

<style lang="scss">
$bgGroup: url("../../static/images/20200609/bg-group.png") no-repeat;
.btn-orange {
  background: #f77e5e;
  color: #fff;
  border-color: #f77e5e;
  &:hover {
    background: #fd9676;
    border-color: #fd9676;
    color: #fff;
  }
}
.amap-wrapper {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
}
.amap-inner {
  width: 740px;
  box-sizing: border-box;
  padding: 20px;
  height: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 1px solid #999;
  z-index: 99999;
  background: #fff;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}
.container {
  width: 700px;
  height: 500px;
}
.search-box {
  position: absolute;
  z-index: 5;
  width: 70%;
  left: 15%;
  top: 20px;
  height: 30px;
  [class*=" el-icon-"],
  [class^="el-icon-"] {
    font-size: 20px;
  }
}
.search-box input {
  float: left;
  width: 90%;
  height: 100%;
  border: 1px solid #f77e5e;
  padding: 0 8px;
  outline: none;
}
.search-box button {
  float: left;
  width: 10%;
  height: 100%;
  background: #f77e5e;
  border: 1px solid #f77e5e;
  color: #fff;
  outline: none;
}
.tip-box {
  width: 100%;
  max-height: 260px;
  position: absolute;
  top: 30px;
  overflow-y: auto;
  background-color: #fff;
}
.toolbar {
  line-height: 32px;
  font-size: 14px;
  span {
    color: #f77e5e;
    font-weight: bold;
  }
  &-bd {
    text-align: center;
  }
  .el-button {
    border-color: #f77e5e;
    background: #f77e5e;
    margin: 0 20px;
    padding: 8px 20px;
    font-size: 14px;
    span {
      color: #fff;
    }
  }
}
</style>
