<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">请选择投稿类型</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
          <!-- <a class="btn-back right" href="javascript:;" onclick="window.history.go(-1)">返回</a> -->
        </div>
        <div class="fjs-tglist">
          <router-link
            v-if="isSingle"
            class="tg-item tg-single"
            :to="'/contribute/' + id + '/' + type + '/single?group=' + gid"
            ><img :src="bgImg[0]"
          /></router-link>
          <router-link
            v-if="isMulti"
            class="tg-item tg-multi"
            :to="'/contribute/' + id + '/' + type + '/multi?group=' + gid"
            ><img :src="bgImg[1]"
          /></router-link>
          <router-link
            v-if="isGroup"
            class="tg-item tg-multi"
            :to="'/contribute/' + id + '/' + type + '/group?group=' + gid"
            ><img :src="bgImg[1]"
          /></router-link>
          <router-link
            v-if="isVideo"
            class="tg-item tg-video"
            :to="'/contribute/' + id + '/' + type + '/video?group=' + gid"
            ><img :src="bgImg[2]"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Head from "@/layouts/Head.vue";

export default {
  name: "Contribute",
  data() {
    return {
      bgImg: [
        require("@/static/images/1223/tg-1.png"),
        require("@/static/images/1223/tg-2.png"),
        require("@/static/images/1223/tg-3.png")
      ],
      id: this.$route.params.id,
      type: this.$route.params.type,
      gid: this.$route.query.group,
      isSingle: false,
      isMulti: false,
      isGroup: false,
      isVideo: false
      // 缓存 matchList 初始化列表，用 list 筛选赋值 matchList
    };
  },
  mounted() {
    // console.log(this.type);
    this.isSingle = this.type.indexOf("single") > -1;
    this.isMulti = this.type.indexOf("multi") > -1;
    this.isGroup = this.type.indexOf("group") > -1;
    this.isVideo = this.type.indexOf("video") > -1;
  }
};
</script>

<style lang="scss">
.fjs-tglist {
  justify-content: space-around;
}
</style>
