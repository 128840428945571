<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">战队投稿</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
        </div>
        <div class="contest-con">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="140px"
            class="demo-ruleForm info-box clearfix"
          >
            <div class="info-box clearfix">
              <div class="left">
                <el-form-item label="作品名称：" prop="mname">
                  <el-input
                    v-model="form.mname"
                    placeholder="请输入15字以内作品名称"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-for="(item, index) in define"
                  :label="item + '：'"
                  :prop="custom_fields[index]"
                  :key="index"
                  :rules="[
                    {
                      required: true,
                      message: '请输入' + item,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="form.custom_fields[index]"
                    :placeholder="'请输入' + item"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item
                  label="作品拍摄地："
                  prop="shoot_address"
                  v-if="gourp_limit.is_open_psd > 0"
                >
                  <el-input
                    v-model="form.shoot_address"
                    placeholder="请输入作品拍摄地"
                  ></el-input>
                </el-form-item> -->
                <el-form-item label="作品拍摄地：" prop="shoot_address">
                  <el-input
                    v-model="form.shoot_address"
                    placeholder="点击选择拍摄地"
                  ></el-input>
                  <!--<el-select v-model="form.shoot_address" placeholder="请选择作品拍摄地" filterable allow-create default-first-option>-->
                    <!--<el-option  v-for="item in xp_address_list" :key="item" :label="item"-->
                                <!--:value="item"></el-option>-->
                  <!--</el-select>-->
                </el-form-item>
                <el-form-item label="作品描述：" prop="remark">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.remark"
                    maxlength="1000"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                <div class="info-item" style="display: block;">
                  <a
                    class="btn btn-bj"
                    href="javascript:void(0)"
                    @click="triggerUpload()"
                  >
                    <!-- <input class="file" type="file" multiple /> -->
                    从本机选择图片</a
                  >
                  <!-- <a class="btn btn-tk" href="">从我的图库中选择图片</a> -->

                  <a class="btn btn-tk" href="javascript:;" @click="showGallery"
                    >从我的图库中选择图片</a
                  >
                  <p class="tips-txt" v-show="msg">
                    {{ msg }}
                  </p>
                  <p class="tips-txt">
                    （本赛事仅支持{{ gourp_limit.typestr }}格式且不大于{{
                      gourp_limit.size
                    }}M的图片）
                  </p>
                </div>
              </div>
              <div class="right">
                <!-- <div class="upload-singlepic-wrap">
                <img class="upload-singlepic" :src="singleUploadBg" />
                <div class="progress-wrap">
                  <p class="progress-txt">0%</p>
                  <progress max="100" value="0" id="progressbar"></progress>
                </div>
              </div>
              <input type="file" name="file" id="file" /> -->
                <el-upload
                  class="upload-singlepic-wrap"
                  id="single"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :http-request="handleUpload"
                >
                  <img
                    v-if="imageUrl"
                    :src="imageUrl"
                    class="upload-singlepic"
                    @click="clearTips()"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div class="imgLoading-bg" v-show="loading">
                    <div class="imgLoading">
                      <span></span><span></span><span></span><span></span
                      ><span></span><span></span><span></span><span></span>
                    </div>
                  </div>
                </el-upload>
                <p class="error"><span v-show="isShowTips">请上传图片</span></p>
              </div>
            </div>
            <div class="submit-box">
              <div class="submit-item">
                <label>
                  <input
                    class="check"
                    type="checkbox"
                    :checked="isAgreement"
                    @click="isAgreement = !isAgreement"
                  />
                  <span class="text">我同意将该图片同步到app发现中</span>
                </label>
              </div>
              <div class="agreement-tips">
                发布即表示您已同意我们的<a
                  href="http://www.pai2345.com/web/Findworks/agreement"
                  target="_blank"
                  >原创条款</a
                >
              </div>
              <div class="submit-item">
                <a class="btn" href="javascript:;" @click="submitForm()"
                  >提交</a
                >
                <!-- <el-button @click="submitForm()">提交</el-button> -->
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Gallery
      :transShowGallery="isShowGallery"
      @changeGallery="hideGallery"
      :end="1"
    />
    <AMap
      v-show="isShowAMap"
      :location="location"
      @changeLocation="changeLocation"
    />
  </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
import AMap from "@/components/AMap.vue";
export default {
  components: {
    Gallery,
    AMap,
  },
  data() {
    return {
      id: this.$route.params.id,
      group: this.$route.query.gid,
      // 是否同意条款
      isAgreement: false,
      define: [],
      custom_fields: [],
      form: {
        mname: "",
        remark: "",
        shoot_address: "",
        custom_fields: [],
      },
      rules: {
        mname: [
          { required: true, message: "请输入作品名称", trigger: "blur" },
          { min: 0, max: 15, message: "长度在 15 个字符", trigger: "blur" },
        ],
        shoot_address: [
          // { required: true, message: "请选择拍摄地点", trigger: "change" },
          // { min: 0, max: 15, message: "长度在 15 个字符", trigger: "blur" },
        ],
        remark: [
          // { required: true, message: "请输入作品描述", trigger: "blur" },
        ],
      },
      loading: false,
      formData: {},
      imageUrl: require("@/static/images/web_single-bg.png"),
      imageBg: require("@/static/images/web_single-bg.png"),
      isShowTips: false,
      has_submit: true,
      msg: "",
      gourp_limit: {
        size: 0,
        imgtype: [],
        typestr: "",
        is_open_psd: 0,
      },
      isShowGallery: false,
      // AMap
      isShowAMap: false,
      location: "",
      detail: {},
        xp_address_list: [
            '宁德市霞浦县',
            '宁德市霞浦县北岐滩涂',
            '宁德市霞浦县牙城镇渡曹村杨家溪景区',
            '宁德市霞浦县三沙镇东壁村',
            '宁德市霞浦县顶1号大京沙滩度假村',
            '宁德市霞浦县沙江镇',
            '宁德市霞浦县三沙镇花竹村',
            '宁德市霞浦县三沙镇',
            '宁德市霞浦溪南镇围江村',
            '宁德市霞浦县溪南镇台江',
            '宁德市霞浦县溪南镇半月里',
            '宁德市霞浦县三沙镇小皓村',
            '宁德市霞浦县浒屿澳村',
            '宁德市霞浦县下青山特大桥',
            '宁德市霞浦县留金村',
            '宁德市霞浦县南湾甲骨文',
            '宁德市霞浦县鹅湾红树林拍摄点',
            '宁德市霞浦县下青山特大桥',
            '宁德市霞浦县牙城镇',
        ], // 霞浦战队赛地址
      // singleUploadBg: require("@/static/images/web_single-bg.png")
    };
  },
  methods: {
    // AMap
    getLocation() {
      this.isShowAMap = true;
    },
    changeLocation(location) {
      console.log(location);
      if (location != "" && location != undefined) {
        // this.form.address = location;
        this.$set(this.form, "shoot_address", location);
      }
      this.isShowAMap = false;
    },
    triggerUpload() {
      document
        .getElementById("single")
        .querySelector(".el-upload__input")
        .click();
    },
    clearTips() {
      this.isShowTips = false;
    },
    showGallery() {
      this.isShowGallery = true;
    },
    handleAvatarSuccess(res, file) {
      // 上传成功改变图片值
      this.imageUrl = URL.createObjectURL(file.raw);
      // 图片上传成功就隐藏提示框
      this.isShowTips = false;
    },
    beforeAvatarUpload(file) {
      //类型判断need imgtype: [],
      let isJPG = this.tools.hasImgType(this.gourp_limit.imgtype, file.type);
      let isLt2M = file.size / 1024 / 1024 < this.gourp_limit.size;
      if (!isJPG) {
        this.$message.error(
          "上传图片只能是 " + this.gourp_limit.typestr + " 格式!"
        );
      }
      if (!isLt2M) {
        this.$message.error(
          "上传图片大小不能超过 " + this.gourp_limit.size + "MB!"
        );
      }
      return isJPG && isLt2M;
    },
    handleUpload(option) {
      var file = option.file;
      var that = this;
      that.loading = true;
      this.tools.uploadaly(file).then((res) => {
        if (res) {
          that.imageUrl = res;
          that.loading = false;
        } else {
          console.log(res);
        }
      });
    },
    hideGallery(arr) {
      this.isShowGallery = false;
      if (arr.length > 0) {
        this.imageUrl = arr[0];
      }
    },
    submitForm() {
      let that = this;
      if (this.has_submit) {
        this.has_submit = false;
        this.$refs["form"].validate((valid) => {
          if (valid) {
            var fase = true;
            if (this.form.mname == "") {
              this.$message.error("作品名称不能为空");
              fase = false;
            }
            // if (fase && this.form.shoot_address == "") {
            //   this.$message.error("拍摄地点不能为空");
            //   fase = false;
            // }
            if (fase && this.imageUrl == this.imageBg) {
              this.isShowTips = true;
              fase = false;
            }
            if (fase && this.loading) {
              this.$message.error("图片上传中，请稍候...");
              fase = false;
            }
            // if (fase && this.gourp_limit.is_open_psd == 2) {
            //   if (this.form.shoot_address == "") {
            //     this.$message.error("图片拍摄地必须填写");
            //     fase = false;
            //   }
            // }
            if (fase && this.define.length > 0) {
              for (var n = 0; n < this.define.length; n++) {
                if (
                  this.form.custom_fields[n] == "" ||
                  this.form.custom_fields[n] == undefined
                ) {
                  this.$message.error(this.define[n] + "必须填写");
                  fase = false;
                  break;
                }
              }
            }
            if (fase) {
              this.axios
                .post(this.tools.api.contributeTeam, {
                  work_id: this.$route.query.work_id,
                  match_id: that.id,
                  mname: this.form.mname,
                  remark: this.form.remark,
                  // address: this.form.address,
                  image: this.imageUrl,
                  group: this.group,
                  shoot_address: this.form.shoot_address,
                  custom_fields: this.form.custom_fields,
                  is_finding: this.isAgreement,
                  works_type: 1,
                  source: 2,
                  uid: this.$route.query.id,
                })
                .then(
                  (res) => {
                    if (res.data.code == 200) {
                      this.$confirm("投稿成功!", "确认信息", {
                        distinguishCancelAndClose: true,
                        confirmButtonText: "再投一稿",
                        cancelButtonText: "返回战队作品页",
                      })
                        .then(() => {
                          var initUrl = window.location.href;
                          initUrl = initUrl.replace("work_id", "init");
                          window.location.href = initUrl;
                          window.location.reload();
                        })
                        .catch(() => {
                          // console.log(action);
                          // this.$router.push({
                          //   path: "/detail/" + that.id + "/myworks",
                          // });
                          this.$router.push({
                            path: "/team/" + this.id,
                            query: {
                              tid: this.$route.query.tid,
                              gid: this.group,
                            },
                          });
                        });
                    } else {
                      this.$message(res.data.msg);
                    }
                    this.has_submit = true;
                  },
                  (err) => {
                    this.has_submit = true;
                    console.log(err);
                  }
                );
            } else {
              this.has_submit = true;
            }
          } else {
            this.has_submit = true;
          }
        });
      }
    },
    init() {
      this.tools
        .getMatchDetail({ id: this.id, group: this.group })
        .then((res) => {
          console.log(res);
          this.define = res.custom_fields;
          this.msg = res.match_limit.match_introduce;
          if (res.match_limit.is_app_show == 1) {
            this.isAgreement = true;
          }
          if (res.gourp_limit) {
            this.gourp_limit = Object.assign(this.gourp_limit, res.gourp_limit);
            this.gourp_limit.size = res.gourp_limit.file_limit_single;
            this.gourp_limit.imgtype = res.gourp_limit.file_limit_type_single;
            this.gourp_limit.typestr = this.gourp_limit.imgtype.toString();
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.work_id) {
      this.tools
        .getWorksDetail({ work_id: this.$route.query.work_id })
        .then((res) => {
          this.form = res.form;
          this.imageUrl = res.image;
          this.id = this.form.m_id;
          this.group = this.form.group;
          this.init();
        });
    } else {
      this.init();
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.contest-submit .info-box .left textarea {
  width: 100%;
}
.el-form-item__label {
  font-size: 18px;
}
.el-input__inner:focus,
.el-input__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.error {
  line-height: 24px;
  height: 24px;
  color: #f56c6c;
}
.btn.disabled {
  background: #ddd;
  border-color: #ddd;
}
.contest-submit .info-box .left .info-item {
  text-align: center;
}
.el-upload {
  height: 100%;
  width: 100%;
}
.el-upload img {
  width: auto;
  height: auto;
}
</style>
